@import "settings/colors";
@import "mixins/media";
@import "mixins/force-width";

.selection-area {
  border: 1px solid $color-blue-700;
  background-color: rgba($color-blue-500, .3);
}

body {
  &.planning-page, &.screener-page {

    .top-bar-menu-wrapper {
      @include media-min-width($xs) {
        display: none !important;
      }
    }
  }
}

.table-page-host {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
}

.mandator-token-input {
  &::placeholder {
    text-transform: initial;
  }
}

iframe#launcher {
  transform: scale(.6) !important;
}

.planning-color-indicator {
  @include force-width(19px);
  height: 19px !important;
  border-radius: 100%;
  border: 1px solid $color-gray-300;
}
